'use client';

import { faPhoneAlt, faMessageQuestion, faCircleInfo, faSeedling } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Image from 'next/image';
import Link from 'next/link';
import { useState } from 'react';
import CidLink from '@/components/marketing/cid-link';

// Define navigation items configuration
const navItems = [{
  href: '/#kontakt',
  text: 'Kontakt',
  icon: faPhoneAlt
}, {
  href: '/#faq',
  text: 'FAQ',
  icon: faMessageQuestion
}, {
  href: '/so-funktionierts',
  text: 'Mieter Informationen',
  icon: faCircleInfo
}, {
  href: '/werte',
  text: 'Nachhaltigkeit',
  icon: faSeedling
}];
export default function Header() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const openMobileMenu = () => setIsMobileMenuOpen(true);
  const closeMobileMenu = () => setIsMobileMenuOpen(false);
  return <header data-sentry-component="Header" data-sentry-source-file="header.tsx">
      <div id="headerheight" className="top-0 w-full fixed z-10">
        <div className="relative bg-white">
          <div className="flex justify-between items-center mx-auto px-4 py-6 sm:px-6 md:space-x-10 lg:px-8">
            <div className="hidden md:flex justify-start lg:w-0 lg:flex-1">
              <Link href="/" data-sentry-element="Link" data-sentry-source-file="header.tsx">
                <span className="sr-only">aareal-aval.de</span>
                <Image className="h-10 w-auto sm:h-12" src="/assets/images/aareal-aval.png" alt="Aareal Aval Logo" width="2081" height="507" data-sentry-element="Image" data-sentry-source-file="header.tsx" />
              </Link>
            </div>
            {/* mobile burger menu */}
            <div className="-mr-2 -my-2 md:hidden">
              <button type="button" className="bg-white rounded-md p-2 inline-flex items-center justify-center text-primary hover:text-neutral hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500" aria-expanded={isMobileMenuOpen} onClick={openMobileMenu}>
                <span className="sr-only">Menü öffnen</span>
                <svg className="h-8 w-8" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true" data-sentry-element="svg" data-sentry-source-file="header.tsx">
                  <path strokeLinecap={'round'} strokeLinejoin={'round'} strokeWidth={'2'} d="M4 6h16M4 12h16M4 18h16" data-sentry-element="path" data-sentry-source-file="header.tsx"></path>
                </svg>
              </button>
            </div>
            {/* mobile logo */}
            <div className="md:hidden justify-center flex">
              <Link href="/" data-sentry-element="Link" data-sentry-source-file="header.tsx">
                <Image className="h-10 w-auto sm:h-10" src="/assets/images/aareal-aval.png" alt="aval logo" width="2081" height="507" data-sentry-element="Image" data-sentry-source-file="header.tsx" />
              </Link>
            </div>

            {/* Desktop Menu */}
            <nav className="hidden md:flex space-x-10 items-center">
              {navItems.map(item => <div key={item.href} className="relative">
                  <Link href={item.href} className={'text-base font-medium text-aareal-aval hover:text-aareal-aval-light'} onClick={closeMobileMenu}>
                    {item.text}
                  </Link>
                </div>)}
              {/* Keep CidLink separate */}
              <div className="relative">
                <CidLink data-sentry-element="CidLink" data-sentry-source-file="header.tsx">Antrag vervollständigen</CidLink>
              </div>
            </nav>
          </div>
        </div>
      </div>

      {/* Mobile Menu */}
      {isMobileMenuOpen && <div className="fixed z-30 top-0 inset-x-0 p-2 transform origin-top-right md:hidden">
          <div className="rounded-lg shadow-lg ring-1 ring-aareal-aval ring-opacity-5 bg-white divide-y-2 divide-gray-50">
            <div className="pt-5 pb-6 px-5">
              <div className="flex items-center justify-between">
                <div>
                  <Image className="h-8 w-auto" src="/assets/images/aareal-aval.png" alt="aval logo" width="1387" height="338" />
                </div>
                <div className="-mr-2">
                  <button type="button" className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-700 hover:bg-gray-100 focus:outline-none" onClick={closeMobileMenu}>
                    <span className="sr-only">Close menu</span>
                    <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                      <path strokeLinecap={'round'} strokeLinejoin={'round'} strokeWidth={'2'} d="M6 18L18 6M6 6l12 12"></path>
                    </svg>
                  </button>
                </div>
              </div>
              <div className="mt-6">
                <nav className="grid grid-cols-1 gap-7">
                  {navItems.map(item => <Link key={item.href} href={item.href} className={'-m-3 p-3 flex items-center rounded-lg hover:bg-gray-50'} onClick={closeMobileMenu}>
                      <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-aareal-aval text-white">
                        <FontAwesomeIcon icon={item.icon} size="lg" />
                      </div>
                      <div className={'ml-4 text-base font-medium text-gray-700'}>{item.text}</div>
                    </Link>)}
                  {/* Keep CidLink separate */}
                  <CidLink className="w-full">Antrag vervollständigen</CidLink>
                </nav>
              </div>
            </div>
          </div>
        </div>}
    </header>;
}