'use client';

import Link from 'next/link';
import { useCid } from '@/components/context/data-context';
import { cn } from '@/lib/utils';
import { Button } from '../ui/button';
interface CidLinkProps {
  children: React.ReactNode;
  className?: string;
  href?: string;
}
export default function CidLink({
  children,
  className,
  href = '/'
}: CidLinkProps) {
  const cid = useCid();
  if (!cid) {
    return null;
  }
  return <Link href={`/${cid}${href}`} className={className} data-testid="cid-link" data-sentry-element="Link" data-sentry-component="CidLink" data-sentry-source-file="cid-link.tsx">
      <Button className={cn('bg-aareal-aval hover:bg-aareal-aval-light', className)} data-sentry-element="Button" data-sentry-source-file="cid-link.tsx">
        {children}
      </Button>
    </Link>;
}